import Vue from 'vue';
import Vuex from 'vuex';
import fly from '@/api/index';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    bg: '',
    types: null,
    currentSubType: '',
    homeAnimatedHasPlayed: 0,
    $bowserMobile: false,
  },
  mutations: {
    setBackgroundColor(state, val) {
      state.bg = val;
    },
    setMobile(state, val) {
      state.$bowserMobile = val;
    },
  },
  actions: {
    getTypes({ state }) {
      return new Promise((resolve, reject) => {
        fly.get('/api/product/types').then((res) => {
          const lists = res.data;
          state.types = lists;
          resolve(lists);
        }).catch((e) => {
          reject(e);
        });
      });
    },
  },
  getters: {
    parentTypes: (state) => (state.types ? state.types.filter((item) => Number(item.parent_id) === 0) : null),
    getCurrentTypes: (state) => (parentid) => (state.types ? state.types.filter((item) => Number(item.parent_id) === Number(parentid)) : null),
  },
  modules: {
  },
});
