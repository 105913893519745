import { TweenMax, Power0 } from 'gsap';

class parallax {
  constructor(el, container, internalOffset) {
    this.$el = el || '';
    this.$container = container || '';
    this.internalOffset = internalOffset || 0;
    this.inview = !1;
    this.enabled = !0;
    this.id = Math.round(1e7 * Math.random());
  }
}
parallax.prototype.init = function init() {
  this.enabled = !(this.$el.length === 0 || this.$container.length === 0);
  this.setInitialPosition();
  this.update();
};
parallax.prototype.update = function update(e) {
  if (this.enabled) {
    this.updatePosition(e);
    if (this.inview) {
      this.percentageMax = window.innerHeight + this.containerBCR.height;
      this.percentage = this.percentageMax - this.containerBCR.bottom;
      this.percentage /= this.percentageMax;
      if (this.percentage > 1) {
        this.percentage = 1;
      } else if (this.percentage < 0) {
        this.percentage = 0;
      }
      this.tween.progress(this.percentage).play().pause();
    }
  }
};
parallax.prototype.updatePosition = function updatePosition() {
  if (this.enabled) {
    const xxx = document.querySelector('.about__imgs-container');
    // this.containerBCR = this.$container['0'].getBoundingClientRect();
    if (xxx) {
      this.containerBCR = xxx.getBoundingClientRect();
    } else {
      this.containerBCR = this.$container['0'].getBoundingClientRect();
    }
    this.elBCR = this.$el['0'].getBoundingClientRect();
    this.verticalOffset = this.elBCR.height - this.containerBCR.height;
    if (
      (this.containerBCR.top >= 0 && this.containerBCR.top <= window.innerHeight)
      || (this.containerBCR.bottom >= 0 && this.containerBCR.bottom <= window.innerHeight)
      || (this.containerBCR.top <= 0 && this.containerBCR.bottom >= window.innerHeight)
    ) {
      this.inview = !0;
    } else {
      this.inview = !1;
    }
  }
};
parallax.prototype.setInitialPosition = function setInitialPosition() {
  if (this.enabled) {
    this.reset();
    // TweenMax.set(this.$container, {
    //   height: this.$container['0'].getBoundingClientRect().height
    // - this.$container['0'].getBoundingClientRect().height * this.internalOffset,
    // });
    this.updatePosition();
    // this.initOffset = this.containerBCR.bottom - this.elBCR.bottom;
    this.initOffset = 0;
    TweenMax.set(this.$el, {
      y: this.initOffset,
    });
    this.tween = TweenMax.fromTo(this.$el, 1, {
      y: this.initOffset,
    }, {
      y: this.initOffset + this.verticalOffset,
      ease: Power0.easeNone,
    });
    this.tween.pause();
  }
};
parallax.prototype.reset = function reset() {
  TweenMax.set([this.$el, this.$container], {
    clearProps: 'all',
  });
};
parallax.prototype.disable = function disable() {
  this.enabled = !1;
  this.reset();
};
parallax.prototype.enable = function enable() {
  this.enabled = !0;
};
parallax.prototype.destroy = function destroy() {};

export default parallax;
