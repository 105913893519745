var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{ref:"pageHeader",staticClass:"v4-page-header-container",class:[_vm.isHeadFixed ? '--fixed' : '', _vm.headFixedTheme, _vm.$route.meta.headerBg ? 'bg-white' : ''],staticStyle:{"position":"fixed"}},[_c('div',{staticClass:"header__inner"},[_c('router-link',{staticClass:"logo",attrs:{"tag":"div","to":"/"}}),(_vm.$route.meta.hasSubHeader && _vm.subHeaders)?_c('ul',{staticClass:"sub-header-container"},_vm._l((_vm.subHeaders),function(sub,index){return _c('li',{key:index,staticClass:"sub-header__item",class:{
          actived: sub.actived
        }},[_c('router-link',{staticClass:"item__link",attrs:{"to":{
            path: ("/projects/" + (_vm.$route.params.typeName) + "/" + (sub.parent_id) + "/" + (sub.id))
          }}},[_c('p',{staticClass:"item__text"},[_vm._v(_vm._s(sub.name.en))]),_c('p',{staticClass:"item__text"},[_vm._v(_vm._s(sub.name.cn))])])],1)}),0):_vm._e(),_c('div',{staticClass:"menu",class:{
        hoverClass: !_vm.$bowserMobile,
        mobileHover: _vm.$bowserMobile
      },on:{"click":_vm.navchange}},[_c('i'),_c('i')]),_c('div',{staticClass:"header-sub-container",style:({
        height: '100vh'
      }),on:{"click":function($event){_vm.$bowserMobile && _vm.navClose()}}},[_c('ul',{staticClass:"box"},_vm._l((_vm.subNavs),function(nav,index){return _c('li',{key:index},[(nav.url)?[_c('router-link',{staticClass:"link",class:{
                hoverClass: !_vm.$bowserMobile,
                mobileHover: _vm.$bowserMobile
              },attrs:{"to":{
                path: ("" + (nav.url))
              }}},[_c('p',{staticClass:"en"},[_vm._v(_vm._s(nav.name.en))]),_c('p',{staticClass:"cn"},[_vm._v(_vm._s(nav.name.cn))])])]:[_c('a',{staticClass:"link",attrs:{"href":"javascript:void(0);"}},[_c('p',{staticClass:"en"},[_vm._v(_vm._s(nav.name.en))]),_c('p',{staticClass:"cn"},[_vm._v(_vm._s(nav.name.cn))])])]],2)}),0)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }