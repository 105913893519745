import Fly from 'flyio';
import { Message } from 'element-ui';

const fly = Fly;

const baseURL = process.env.NODE_ENV === 'development'
  ? 'http://api.blvd.cn'
  : 'http://api.blvd.com.cn';

fly.config.baseURL = baseURL;
fly.config.headers = {
  'Content-Type': 'application/json',
};

fly.interceptors.request.use((resquest) => resquest);
fly.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data.status === 0) {
      return data;
    }
    Message({
      message: data.desc,
      type: 'error',
      duration: 2 * 1000,
    });
    return Promise.reject(new Error(data.desc));
  },
  (err) => {
    Message({
      message: err.message,
      type: 'error',
      duration: 2 * 1000,
    });
  },
);

export default fly;
