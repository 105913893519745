<template>
  <header
    style="position:fixed;"
    class="v4-page-header-container"
    :class="[isHeadFixed ? '--fixed' : '', headFixedTheme, $route.meta.headerBg ? 'bg-white' : '']"
    ref="pageHeader"
  >
    <div class="header__inner">
      <router-link class="logo" tag="div" to="/"></router-link>
      <ul class="sub-header-container" v-if="$route.meta.hasSubHeader && subHeaders">
        <li
          v-for="(sub, index) in subHeaders"
          :key="index"
          class="sub-header__item"
          :class="{
            actived: sub.actived
          }"
        >
          <router-link
            class="item__link"
            :to="{
              path: `/projects/${$route.params.typeName}/${sub.parent_id}/${sub.id}`
            }"
          >
            <p class="item__text">{{ sub.name.en }}</p>
            <p class="item__text">{{ sub.name.cn }}</p>
          </router-link>
        </li>
      </ul>
      <div
        class="menu"
        :class="{
          hoverClass: !$bowserMobile,
          mobileHover: $bowserMobile
        }"
        @click="navchange"
      >
        <i></i><i></i>
      </div>
      <div
        class="header-sub-container"
        @click="$bowserMobile && navClose()"
        :style="{
          height: '100vh'
        }"
      >
        <ul class="box">
          <li v-for="(nav, index) in subNavs" :key="index">
            <template v-if="nav.url">
              <router-link
                class="link"
                :class="{
                  hoverClass: !$bowserMobile,
                  mobileHover: $bowserMobile
                }"
                :to="{
                  path: `${nav.url}`
                }"
              >
                <p class="en">{{ nav.name.en }}</p>
                <p class="cn">{{ nav.name.cn }}</p>
              </router-link>
            </template>
            <template v-else>
              <a href="javascript:void(0);" class="link">
                <p class="en">{{ nav.name.en }}</p>
                <p class="cn">{{ nav.name.cn }}</p>
              </a>
            </template>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'page-header',
  data() {
    return {
      subHeaders: null,
      subNavs: [
        {
          name: {
            cn: '案例',
            en: 'project',
          },
          url: '/projects',
        },
        {
          name: {
            cn: '关于',
            en: 'about',
          },
          url: '/about',
        },
        {
          name: {
            cn: '奖项',
            en: 'awards',
          },
          url: '/awards',
        },
        {
          name: {
            cn: '新闻',
            en: 'media',
          },
          url: '/list/news',
        },
        {
          name: {
            cn: '联系',
            en: 'contact',
          },
          url: '/contact',
        },
      ],
      navSwitch: false,
    };
  },
  computed: {
    ...mapState(['types', '$bowserMobile']),
    isHeadFixed() {
      return this.$route.meta.isHeadFixed;
    },
    headFixedTheme() {
      return this.$route.meta.fixedTheme;
    },
  },
  watch: {
    navSwitch(val) {
      if (val) {
        document.body.classList.add('nav-open');
      } else {
        document.body.classList.remove('nav-open');
      }
    },
    types: {
      handler() {
        this.subHeadersInit();
      },
      immediate: true,
    },
    $route: {
      handler() {
        this.navSwitch = false;
        this.subHeadersInit();
      },
      immediate: true,
    },
  },
  methods: {
    navchange() {
      this.navSwitch = !this.navSwitch;
    },
    navClose() {
      this.navSwitch = false;
    },
    subHeadersInit() {
      const that = this;
      const parentid = Number(that.$route.params.parentid);
      const typeid = that.$route.params.typeid ? Number(that.$route.params.typeid) : 0;
      if (that.types && that.$route.meta.hasSubHeader) {
        const data = that.types ? that.types.filter((item) => item.parent_id === parentid) : null;
        data.unshift({
          id: 0,
          name: {
            en: 'all',
            cn: '全部',
          },
          parent_id: parentid,
        });

        that.subHeaders = data.map((item) => {
          const temp = item;
          temp.actived = Number(item.id) === typeid;
          if (Number(item.id) === typeid) {
            that.$store.state.currentSubType = item.name.en;
          }
          return temp;
        });
      }
      if (!that.$bowserMobile) {
        document.body.classList.remove('white-logo', 'red-logo-white-menu');
      }
    },
  },
};
</script>

<style></style>
