export function parseImage(url) {
  // 静态地址
  return process.env.VUE_APP_STATIC_PATH + url;
}

function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return `${time + label}s`;
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  }
  return pluralize(~~(between / 86400), ' day');
}

export function parseTime(time, cFormat) {
  let retime = time;
  if (arguments.length === 0) {
    return null;
  }

  if (retime === 0 || retime === '' || retime === '0') {
    return '无';
  }

  if ((`${retime}`).length === 10) {
    retime = +retime * 1000;
  }

  // const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  const format = cFormat || '{y}-{m}-{d}';
  let date;
  if (typeof retime === 'object') {
    date = retime;
  } else {
    date = new Date(parseInt(retime, 10));
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
    enm: date.getMonth(),
  };
  const timeStr = format.replace(/{(y|m|d|h|i|s|a|enm)+}/g, (result, key) => {
    let val = formatObj[key];
    if (key === 'a') {
      return ['一', '二', '三', '四', '五', '六', '日'][val - 1];
    }
    if (key === 'enm') {
      return ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'spetember', 'october', 'november', 'december'][val - 1];
    }
    if (result.length > 0 && val < 10) {
      val = `0${val}`;
    }
    return val || 0;
  });
  return timeStr;
}

export function formatTime(time, option) {
  const retime = +time * 1000;
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } if (diff < 3600) { // less 1 hour
    return `${Math.ceil(diff / 60)}分钟前`;
  } if (diff < 3600 * 24) {
    return `${Math.ceil(diff / 3600)}小时前`;
  } if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(retime, option);
  }
  return `${d.getMonth() + 1}月${d.getDate()}日${d.getHours()}时${d.getMinutes()}分`;
}

export function html2Text(val) {
  const div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

export function toThousandslsFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}
