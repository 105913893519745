import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/index'),
    meta: {
      isHeadFixed: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../pages/about'),
  },
  // 项目父类
  {
    path: '/projects',
    name: 'page-project-type',
    component: () => import('../pages/type'),
  },
  // 项目总表
  {
    path: '/projects/:typeName/:parentid/:typeid?',
    name: 'page-project-lists-total',
    component: () => import('../pages/project-lists'),
    props: (route) => ({
      parentid: Number(route.params.parentid),
      typeid: route.params.typeid ? Number(route.params.typeid) : 0,
      typeName: route.params.typeName,
    }),
    meta: {
      hasSubHeader: true,
      whiteLogo: true,
    },
  },
  // 项目列表 theme 1
  // {
  //   path: '/projects/:typeName/:parentid/:typeid?',
  //   name: 'page-list-theme-2',
  //   component: () => import('../pages/list-theme-2'),
  //   props: (route) => ({
  //     parentid: Number(route.params.parentid),
  //     typeid: route.params.typeid ? Number(route.params.typeid) : 0,
  //   }),
  //   meta: {
  //     hasSubHeader: true,
  //     whiteLogo: true,
  //   },
  // },
  // 项目列表 theme 2
  // {
  //   path: '/grid/:parentid/:typeid?',
  //   name: 'page-list-theme-other',
  //   component: () => import('../pages/list-2'),
  //   props: (route) => ({
  //     parentid: Number(route.params.parentid),
  //     typeid: route.params.typeid ? Number(route.params.typeid) : 0,
  //   }),
  //   meta: {
  //     hasSubHeader: true,
  //     whiteLogo: true,
  //   },
  // },
  {
    path: '/project/:id',
    name: 'project-detail',
    component: () => import('../pages/detail'),
    props: (route) => ({
      id: Number(route.params.id),
    }),
  },
  {
    path: '/awards',
    name: 'awards',
    component: () => import('../pages/awards'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../pages/contact'),
  },
  {
    name: 'news-publication',
    component: () => import('../pages/np-container'),
    path: '/list',
    children: [
      {
        path: 'news',
        name: 'news-list',
        component: () => import('../pages/news'),
        meta: {
          defaultBg: '#fff',
        },
      },
      {
        path: 'publication',
        name: 'publication',
        component: () => import('../pages/publication-2'),
        meta: {
          defaultBg: '#f0efed',
        },
      },
    ],
  },
  {
    path: '/news/:id',
    name: 'news-detail',
    component: () => import('../pages/news-detail'),
    props: (route) => ({
      id: Number(route.params.id),
    }),
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../pages/test'),
  },
  {
    path: '/files',
    name: 'upload-files',
    component: () => import('../pages/files'),
  },
  {
    path: '/interior/?typeid',
  },
  {
    path: '/architecture/?typeid',
  },
  {
    path: '/landscape/?typeid',
  },
  {
    path: '/urban/?typeid',
  },
];

const router = new VueRouter({
  mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savePosition) {
    console.log(to, from, savePosition);
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
