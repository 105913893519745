import fly from './base';

export default {
  get(url, params) {
    return fly.request({
      url,
      method: 'get',
      body: params,
    });
  },
  post(url, params) {
    return fly.request({
      url,
      method: 'post',
      body: params,
    });
  },
  put(url, params) {
    return fly.request({
      url,
      method: 'put',
      body: params,
    });
  },
  del(url, params) {
    return fly.request({
      url,
      method: 'delete',
      body: params,
    });
  },
  multi(requests) {
    return fly.all(requests);
  },
};
