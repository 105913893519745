import {
  TweenMax, Power1, TimelineLite, TweenLite, Power3, Linear, Expo, Cubic,
} from 'gsap';
import * as PIXI from 'pixi.js';
import $ from 'jquery';
import bowser from 'bowser';
import Parallax from './parallax';

const windowHeight = $(window).height();
const bottomHeight = windowHeight * 0.95;
const $window = $(window);
const $body = $('body');

let ticking = false;

const defaultBg = document.body.style.backgroundColor;

export function loopup(obj) {
  const $upLine = $(obj);
  const height = $upLine.height();

  const r = new TimelineLite({
    onComplete: () => {
      loopup(obj);
    },
  });
  r.fromTo($upLine, 2.4, {
    y: -height,
  }, {
    y: 0,
    delay: 0.2,
    ease: Expo.easeOut,
  })
    .to($upLine, 0.6, {
      y: height,
      delay: 1,
      ease: Cubic.easeIn,
    }, 2.4);
}

function loopleft(obj) {
  const $upLine = $(obj);
  const height = $upLine.width();

  const r = new TimelineLite({
    onComplete: () => {
      loopleft(obj);
    },
  });
  r.fromTo($upLine, 2.4, {
    x: height,
  }, {
    x: 0,
    delay: 0.2,
    ease: Expo.easeOut,
  })
    .to($upLine, 0.6, {
      x: -height,
      delay: 1,
      ease: Cubic.easeIn,
    }, 2.4);
}

function animateValue(id, start, end, duration) {
  const range = end - start;
  let current = end === 4000 ? 3800 : start;
  const increment = end > start ? 1 : -1;
  const stepTime = Math.abs(Math.floor(duration / range));
  const obj = $(id)['0'];
  const timer = setInterval(() => {
    current += increment;
    obj.innerHTML = current;
    if (current === end) {
      clearInterval(timer);
    }
  }, stepTime);
}

function home2Text() {
  const home2Item = Array.from($('.home2__item'));
  if (home2Item.length > 0) {
    const screenHeight = $window.height();
    const parent = $('.home2__wrapper');
    // console.log($window.scrollTop());

    // 开始固定
    if ($('.title__copy').eq('0')['0'].getBoundingClientRect().top <= screenHeight * 0.5) {
      parent.find('.item__content').css({
        position: 'fixed',
      });
    }

    home2Item.forEach((item) => {
      const temp = item;
      // const bcr = temp.getBoundingClientRect();

      const half = Number(temp.dataset.half);
      const imgBcr = temp.querySelector('.item__image').getBoundingClientRect();
      const textBcr = temp.querySelector('.white .title__copy').getBoundingClientRect();

      // 处理x轴
      let whiteRight;
      let blackLeft;
      let y;
      if (half === 1) {
        whiteRight = `${textBcr.width - (imgBcr.width - (textBcr.x - imgBcr.x))}px`;
        blackLeft = `${imgBcr.width - (textBcr.x - imgBcr.x)}px`;
      } else {
        whiteRight = '0';
        blackLeft = '100%';
      }
      if (imgBcr.top > 0 && imgBcr.top <= (screenHeight * 0.5)) {
        // 上半部分
        // 处理y轴
        // temp.find('.black .title__copy');
        // temp.find('.white .title__copy');
        y = 0;
      } else if (imgBcr.top < 0 && (imgBcr.height + imgBcr.top >= textBcr.top)) {
        console.log(imgBcr.height + imgBcr.top - 50, textBcr.top);
        // imgBcr.top < 0 && -imgBcr.top <= (screenHeight * 0.47)
        // 下半部分
        y = 0;
      } else {
        y = '100%';
      }

      // console.log(imgBcr, textBcr);

      temp.querySelector('.white .title__copy').style.cssText = `clip-path: inset(${y} ${whiteRight} 0 0);`;
      temp.querySelector('.black .title__copy').style.cssText = `clip-path: inset(${y} 0 0 ${blackLeft});`;
      // console.log(whiteRight, blackLeft);
    });
    // console.log(screenHeight);
  }
}

function onPromiseImageLoad(src) {
  const p = new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = function () {
      reject(new Error('图片错误'));
    };
    img.src = src;
  });
  return p;
}

function scrollToShow() {
  function doshow(obj, k) {
    const extend = $(obj).data('extend');
    setTimeout(() => {
      $(obj).addClass('animated');
      if (extend === 'number') {
        const text = $(obj).find('.number__text');
        const end = text.data('end');
        animateValue(text, 0, end, 1000);
      }
    }, k * 100);
  }

  // 手机端 about 大字
  const bigText = document.querySelector('.mobileMove');
  if (bigText) {
    const bcr = bigText.getBoundingClientRect();
    const upLimit = window.innerHeight / 1.2;
    if (bcr.top <= window.innerHeight && bcr.top >= window.innerHeight - upLimit) {
      const rate = ((window.innerHeight - bcr.top) / upLimit) * 100 * 1.2;
      requestAnimationFrame(() => {
        bigText.style.cssText = `transform:translate3d(${-rate}%,0,0)`;
      });
    }
    // console.log(bcr);
  }

  home2Text();

  const $jsScrollGolbal = $('.js-fixed-demo2');
  if ($jsScrollGolbal.length > 0) {
    const $bannerImgContainer = $('.banner-img-container');
    if ($bannerImgContainer['0'].getBoundingClientRect().top > 0) {
      TweenMax.set($jsScrollGolbal, {
        y: -document.body.getBoundingClientRect().y,
      });
    }
  }

  const demo2header = $('.demo2-header-container');
  const logo = demo2header.find('.logo');
  const navContainer = demo2header.find('.nav-container');
  if (demo2header.length > 0) {
    const scroll = $window.scrollTop();
    if (!demo2header.hasClass('theme-white')) {
      if (scroll > 100) {
        if (!demo2header.hasClass('drop-show')) {
          demo2header.addClass('drop-show');
          const r = new TimelineLite({
            onComplete: () => { },
          });
          r.add(TweenMax.fromTo(logo, 0.3, {
            opacity: 0,
          }, {
            opacity: 1,
            ease: Cubic.easeInOut,
          }), 0);
          r.add(TweenMax.fromTo(navContainer, 0.3, {
            opacity: 0,
          }, {
            opacity: 1,
            ease: Cubic.easeInOut,
          }), 0);
        }
        // $('#scroll-container').addClass('close');
      } else {
        demo2header.removeClass('drop-show');
        // $('#scroll-container').removeClass('close');
      }
    }
  }

  const scolls = [...document.querySelectorAll('.scrollObj')].filter((item) => {
    const relatedObj = item.dataset.related;
    const name = item.dataset.name || undefined;
    const cn = item.dataset.cn || undefined;
    const area = item.dataset.area || undefined;
    let xxx = null;
    if (relatedObj) {
      xxx = $(item).parents('.relatedFather').find(`.${relatedObj}`);
    }
    const isAnimated = item.classList.contains('animated');
    const { top, height } = relatedObj ? xxx['0'].getBoundingClientRect() : item.getBoundingClientRect();
    const isInView = top <= bottomHeight && top > 0;
    if (isAnimated && relatedObj && !isInView && (top - height > 0)) {
      item.classList.remove('animated');
    }

    const bodyY = -document.body.getBoundingClientRect().top;

    if (isInView && name && cn && area) {
      const bar = $('#fixed-side-bar-text');
      const footerBCR = $('.demo2-footer-container')['0'].getBoundingClientRect();
      const footerInView = footerBCR.y <= (bottomHeight * 1.3) && footerBCR.y > 0;
      if (footerInView) {
        // bar.css({
        //   opacity: 0,
        // });
        bar.removeClass('show');
      } else if (bodyY > 300) {
        bar.addClass('show');
      } else if (bodyY <= 300) {
        bar.removeClass('show');
      }
      bar.find('p').eq(0).text(area);
      bar.find('p').eq(1).text(name);
      bar.find('p').eq(2).text(cn);
    }

    if ($('.fixed-side-bar').length === 1) {
      if (bodyY > 200) {
        $('.fixed-side-bar').addClass('show');
      } else {
        $('.fixed-side-bar').removeClass('show');
      }
    }

    // 加背景
    if (item.dataset.extend && item.dataset.extend === 'bg') {
      const { color } = item.dataset;
      const redlogo = item.dataset.logo;
      if (isInView || top < 0) {
        if (color === '#000') {
          document.body.classList.add('bg-black');
          document.body.classList.add('white-logo');
        }
        document.body.style.cssText = `background-color: ${color}`;
        if (Number(redlogo) === 1) {
          document.body.classList.add('red-logo');
        }
      } else {
        document.body.classList.remove('bg-black');
        document.body.style.cssText = `background-color: ${defaultBg}`;
        if (Number(redlogo) === 1) {
          document.body.classList.remove('red-logo');
          if (color === '#000') {
            document.body.classList.remove('white-logo');
          }
        }
      }
    } else if (item.dataset.extend && item.dataset.extend === 'spbg') {
      const cl = item.dataset.logo;
      const { logoclass, offset } = item.dataset;
      const hasW = document.body.classList.contains('white-logo');
      if (isInView || top < 0) {
        item.classList.add('bg-show');
        if (top < 100 && offset === 'top') {
          if (Number(cl) === 1) {
            if (hasW) {
              // document.body.classList.remove('white-logo');
              document.body.classList.add(logoclass);
            }
          }
        } else {
          document.body.classList.remove(logoclass);
        }
      } else {
        item.classList.remove('bg-show');
        if (Number(cl) === 1) {
          if (hasW) {
            // document.body.classList.add('white-logo');
            document.body.classList.remove(logoclass);
          }
        }
      }
    } else if (item.dataset.extend && item.dataset.extend === 'delay') {
      const delayDur = item.dataset.duration || 300;
      if ((isInView || top < 0) && $window.scrollTop() >= delayDur) {
        item.classList.add('delay');
      } else {
        item.classList.remove('delay');
      }
    } else if (item.dataset.extend && item.dataset.extend === 'shrink') {
      if (top * 3 <= height) {
        item.classList.add('--shrink');
      } else {
        item.classList.remove('--shrink');
      }
    } else if (item.dataset.extend && item.dataset.extend === 'fixedshow') {
      const relatedObj1 = document.querySelector(`.${item.dataset.relatedshow}`);
      const { rate } = item.dataset;
      if (relatedObj1) {
        if (relatedObj1.getBoundingClientRect().top < window.innerHeight) {
          item.classList.add('show');
        } else {
          item.classList.remove('show');
        }
      } else if ($(window).scrollTop() + $(window).height() >= $('.page-common-wrapper').height() * rate) {
        if ($(window).scrollTop() > 0) {
          item.classList.add('show');
        } else {
          item.classList.remove('show');
        }
      } else {
        item.classList.remove('show');
      }
    } else if (item.dataset.extend && item.dataset.extend === 'logodown') {
      if (isInView || top < 0) {
        document.body.classList.add('logo-down');
      } else {
        document.body.classList.remove('logo-down');
      }
    }
    return !isAnimated && isInView && (item.dataset.extend !== 'delay');
  });

  const awardspheader = document.querySelector('#awardspheader');
  if (awardspheader && !bowser.mobile) {
    if ($window.scrollTop() >= 200 && !awardspheader.classList.contains('show')) {
      awardspheader.classList.add('show');
    }
  }
  scolls.forEach((item, key) => {
    if ($(item).find('.lazyload').not('.loaded').length === 1) {
      const lazy = $(item).find('.lazyload');
      const src = lazy.data('src');
      const ps = [];
      if (src.indexOf(',')) {
        const srcs = src.split(',');
        srcs.forEach((itemSrc) => {
          ps.push(onPromiseImageLoad(itemSrc));
        });
      } else {
        ps.push(onPromiseImageLoad(src));
      }

      Promise.all(ps).then((ws) => {
        const shape = lazy.data('shape');
        const xxx = ws.sort((a, b) => b.height - a.height);
        if (shape) {
          if (shape === 'big') {
            lazy.attr('src', xxx['0'].src);
          } else {
            lazy.attr('src', xxx['1'].src);
          }
        } else {
          lazy.attr('src', xxx['0'].src);
        }
        setTimeout(() => {
          lazy.addClass('loaded');
        }, 800);
      });
    }
    doshow(item, key);
  });
  ticking = false;
}

function onScroll() {
  if (!ticking) {
    requestAnimationFrame(scrollToShow);
    ticking = true;
  }
}

export function demo2OnScroll() {
  if (!ticking) {
    requestAnimationFrame(scrollToShow);
    ticking = true;
  }
}

class Stripe {
  constructor(t, s) {
    this.scrollBar = s;
    this.$el = t;
    this.types = {};
    this.tweens = {};
    this.boundaries = {};
  }
}

Stripe.prototype.init = function () {
  const that = this;
  const tween = {
  };
  that.$el.each((i, item) => {
    const $item = $(item);
    const type = $item.data('parallx');
    const start = $item.data('start') || '36%';
    const end = $item.data('end') || '-25%';
    const startTransform = {
    };
    const endTransform = {
    };
    let transformObj = null;
    this.types[i] = type;
    switch (type) {
      case 'clip':
        startTransform.scale = 0.8;
        endTransform.scale = 1;
        endTransform.ease = Power1.easeInOut;
        transformObj = $item;
        break;
      case 'parallx':
        startTransform.y = start;
        endTransform.y = end;
        endTransform.ease = Power1.easeInOut;
        transformObj = $item.children('.dom-parallax-child');
        break;
      default:
        break;
    }
    TweenMax.set(transformObj, startTransform);
    tween[i] = TweenMax.fromTo(transformObj, 10, startTransform, endTransform);
    tween[i].pause();
  });
  this.tweens = tween;
};
Stripe.prototype.update = function () {
  // const xxx = 0;
  const that = this;
  const {
    innerHeight,
  } = window;
  that.$el.each((...item) => {
    const i = item['0'];
    that.computeData();
    const type = that.types[i];
    let offsetScroll = 0;
    let percentage = 0;
    offsetScroll = innerHeight - that.boundaries[i].top;
    percentage = offsetScroll / (that.boundaries[i].height * (type === 'parallx' ? 1.01 : 0.75));
    if (percentage <= 0) {
      percentage = 0;
    } else if (percentage > 1) {
      percentage = 1;
    }
    that.tweens[i].progress(percentage).play().pause();
  });
};
Stripe.prototype.computeData = function () {
  const that = this;
  const {
    scrollY,
  } = window;
  that.$el.each((i, item) => {
    // const type = that.types[i];
    const BCR = item.getBoundingClientRect();
    that.boundaries[i] = {
      top: scrollY + BCR.top,
      bottom: scrollY + BCR.top + BCR.height,
      height: BCR.height,
    };
  });
};

const parallax = {
  init(...config) {
    this.scrollBar = config['1'];
    this.$el = window.$('.dom-parallax');
    if (this.$el.length > 0) {
      this.stripe = new Stripe(this.$el, this.scrollBar);
      this.stripe.init();
    }
  },
  update() {
    if (this.$el.length > 0) {
      this.stripe.update();
    }
  },
};

export function bindScroll(route) {
  // 图片parallax
  const $picWrapper = $('.parallax-container');
  const $picture = $picWrapper.find('img');
  const imgParallax = new Parallax($picture, $picWrapper);
  imgParallax.init();

  if (route.name !== 'project-list') {
    window.scrollbar = window.Scrollbar.init(document.getElementById('page-common-wrapper'), {});
  }

  scrollToShow(undefined, window.scrollbar);
  parallax.init(undefined, window.scrollbar);
  const $jsScrollGolbal = $('.js-fixed-global');
  // window.addEventListener('scroll', onScroll);
  // about 弹窗
  const $listItem = window.$('.list__item');
  if ($listItem.length > 0) {
    $listItem.on('click', function (e) {
      const $this = $(this);
      const child = $this.find('.main-content__wrapper')['0'];
      const wrapper = $this.find('.item__main-content');
      if ($this.hasClass('opened')) {
        wrapper.css({
          height: '0px',
        });
        $this.removeClass('opened');
      } else {
        wrapper.css({
          height: `${child.getBoundingClientRect().height}px`,
        });
        setTimeout(() => {
          $this.addClass('opened');
        }, 100);
      }
      e.stopPropagation();
    });
  }
  // 计算about长度
  const orTop = 600;
  // let atop = 0;
  $('.about__section-container .section__fixed-text .text').each((...item) => {
    const $this = item['1'];
    const selfBcr = $this.getBoundingClientRect();
    const offset = $this.dataset.offset ? $this.dataset.offset : orTop;
    $($this).css({
      // top: `${item['0'] === 0 ? orTop : (orTop - atop)}px`,
      // top: `${-bcr.top - bcr.height + selfBcr.height}px`,
      top: `${-selfBcr.top + Number(offset)}px`,
    });
    // atop += $($this).parents('.about__section-container')['0'].getBoundingClientRect().height;
  });

  if (route.name !== 'project-list') {
    // scroll 事件监控
    window.scrollbar.addListener((status) => {
      //
      imgParallax.update();
      // 向上
      scrollToShow(status, window.scrollbar);
      parallax.update();
      // fixed修改
      TweenMax.set($jsScrollGolbal, {
        y: status.offset.y,
      });
      // const $sticky = $('.sticky-top');
      // const c = 70;
      // $sticky.each((key, item) => {
      //   const bcr = item.getBoundingClientRect();

      //   if (bcr.top <= c) {
      //     TweenMax.set(item, {
      //       y: 0,
      //     });
      //   }
      // });
    });
  } else {
    window.addEventListener('scroll', onScroll);
  }

  // 红线js
  const line = $('.inner-line');
  if (line.length > 0) {
    loopup(line);
  }
  // 红线 left
  const leftLine = $('.left-inner-line');
  if (leftLine.length > 0) {
    loopleft(leftLine);
  }
}

export function demo2init() {
  // 红线 left
  const leftLine = $('.left-inner-line');
  if (leftLine.length > 0) {
    loopleft(leftLine);
  }
}

export const slideShow = {
  init() {
    this.view = document.querySelector('.index-hero-container');
    this.$collection = this.view.querySelector('.jsCollection');
    this.$items = this.view.querySelectorAll('.jsItem');
    this.$canvas = document.querySelector('.jsCanvas');
    // this.$progress = this.view.querySelector('.jsProgress');
    this.$previous = this.view.querySelector('.prev');
    this.$next = this.view.querySelector('.next');
    this.OLD_INDEX = 0;
    this.CURRENT_INDEX = 0;
    this.TOTAL = this.$items.length - 1;
    this.DIRECTION = 0;
    this.IS_MOVING = false;
    this.indicator = 0;
    this.durationAutoplay = 5;
    this.timerAutoplay = 0;
    this.circleTween = 0;
    this.progressCircle = {
      progress: 0,
    };

    this.renderer = new PIXI.autoDetectRenderer({
      width: 100,
      height: 100,
      transparent: true,
      antialias: true,
    });
    this.stage = new PIXI.Container();
    this.circleGraphic = new PIXI.Graphics();
    this.circleSettings = {
      color: 16777215,
      radius: 10,
      degree: 270,
      initAngle: this.degreeToRadian(270),
      firstDraw: this.degreeToRadian(270),
    };
    this.circleTween = false;
    this.progressCircle = {
      progress: 0,
    };
    this.setup();
    this.launch();
  },
  launch() {
    const t = this;
    if (t.TOTAL > 0) {
      const i = t.$items[t.CURRENT_INDEX];
      const u = i.querySelector('.bg');
      TweenLite.fromTo(u, this.durationAutoplay + 3, {
        scale: 1.04,
      },
      {
        scale: 1,
        ease: Power3.easeOut,
        delay: 0.2,
      });
      setTimeout(() => {
        t.autoplay();
      }, 1100);
      t.animateCircle();
    }
  },
  animateCircle() {
    const t = this;
    this.progressCircle = {
      progress: 0,
    };
    this.circleTween = TweenLite.to(this.progressCircle, this.durationAutoplay + 1, {
      immediateRender: false,
      progress: 1,
      ease: Linear.easeNone,
      onUpdate() {
        let e = 360 * t.progressCircle.progress;
        e += t.circleSettings.degree;
        const i = t.degreeToRadian(e);
        t.circleGraphic.clear();
        t.circleGraphic.beginFill(t.circleSettings.color, 1);
        t.circleGraphic.lineStyle();
        t.circleGraphic.arc(
          t.circleSettings.radius,
          t.circleSettings.radius,
          t.circleSettings.radius,
          t.circleSettings.initAngle,
          i,
        );
        t.circleGraphic.lineTo(t.circleSettings.radius, t.circleSettings.radius);
        t.circleGraphic.endFill();
        t.renderer.render(t.stage);
      },
    });
  },
  degreeToRadian(t) {
    return (t * Math.PI) / 180;
  },
  goToNext() {
    if (!this.IS_MOVING) {
      this.DIRECTION = 1;
      this.CURRENT_INDEX += 1;
      if (this.CURRENT_INDEX > this.TOTAL) {
        this.CURRENT_INDEX = 0;
      }
      this.move();
    }
  },
  goToPrevious() {
    if (!this.IS_MOVING) {
      this.DIRECTION = -1;
      this.CURRENT_INDEX -= 1;
      if (this.CURRENT_INDEX < 0) {
        this.CURRENT_INDEX = this.TOTAL;
      }
      this.move();
    }
  },
  move() {
    const t = this;
    const e = this.$items[this.OLD_INDEX];
    const i = this.$items[this.CURRENT_INDEX];
    const u = i.querySelector('.bg');
    const h = i.querySelectorAll('.jsTextFx');
    const r = new TimelineLite({
      onComplete() {
        // TweenLite.to(u, t.durationAutoplay, {
        //   scale: 1,
        // });
        t.twIsComplete();
      },
    });
    let o = '';
    let s = '';
    const n = this.view.getBoundingClientRect();
    if (this.DIRECTION > 0) {
      o = `rect(0px,0px,${n.height}px,0px)`;
      s = `rect(0px,${n.width}px,${n.height}px,0px)`;
    } else {
      o = `rect(0px,${n.width}px,${n.height}px,${n.width}px)`;
      s = `rect(0px,${n.width}px,${n.height}px,0px)`;
    }
    if (this.OLD_INDEX !== this.CURRENT_INDEX) {
      this.IS_MOVING = true;
      this.updating();
      TweenLite.to(this.$canvas, 0.3,
        {
          scale: 0,
          ease: Power3.easeInOut,
          onComplete() {
            t.circleTween.kill();
            t.progressCircle.progress = 0;
          },
        });
      // TweenLite.to();canvas
      r.kill();
      r.call(() => {
        TweenLite.set(i, {
          clip: o,
        });
        e.style.zIndex = 2;
        i.style.zIndex = 3;
        i.classList.remove('disabled');
        i.classList.add('enabled');
      });

      TweenLite.fromTo(u, this.durationAutoplay + 3, {
        scale: 1.14,
      },
      {
        scale: 1,
        ease: Power3.easeOut,
        delay: 0.2,
      });

      r.add([
        // canvas
        TweenLite.to(this.$canvas, 0.35, {
          scale: 1,
          ease: Power3.easeOut,
          delay: 0.35,
          onStart() {
            t.animateCircle();
          },
        }),
        TweenLite.to(i, 0.7, {
          clip: s,
          ease: Power3.easeInOut,
        }),
        TweenMax.staggerFromTo(h, 1,
          {
            opacity: 0,
            y: 100,
          },
          {
            opacity: 1,
            y: 0,
            delay: 0.5,
            ease: Power3.easeOut,
          }, 0.05),
        // 进度条
        // TweenLite.fromTo(this.$progress, 0.7, {
        //   x: '-100%',
        // },
        // {
        //   x: '100%',
        //   ease: Power3.easeInOut,
        // }),
      ]);

      r.add(() => {
        TweenLite.set(h, {
          clearProps: 'all',
        });
        TweenLite.set(i, {
          clearProps: 'clip',
        });
        TweenLite.set(e, {
          clearProps: 'clip',
        });
      });

      r.restart();
    }
  },
  twIsComplete() {
    this.IS_MOVING = false;
    this.OLD_INDEX = this.CURRENT_INDEX;
    this.finalize();
    this.autoplay();
  },
  autoplay() {
    const t = this;
    this.timerAutoplay = window.setTimeout(() => {
      t.goToNext();
    }, 1e3 * this.durationAutoplay);
  },
  setup() {
    const that = this;
    that.$canvas.appendChild(this.renderer.view);
    if (that.TOTAL > 0) {
      that.drawCircle();
    }
    that.$previous.addEventListener('click', () => {
      clearTimeout(that.timerAutoplay);
      that.goToPrevious();
    });
    that.$next.addEventListener('click', () => {
      clearTimeout(that.timerAutoplay);
      that.goToNext();
    });
    that.updating();
    that.finalize();
  },
  drawCircle() {
    this.circleGraphic.beginFill(this.circleSettings.color, 1);
    this.circleGraphic.arc(
      this.circleSettings.radius,
      this.circleSettings.radius,
      this.circleSettings.radius,
      this.circleSettings.initAngle,
      this.circleSettings.firstDraw,
    );
    this.circleGraphic.lineTo(this.circleSettings.radius, this.circleSettings.radius);
    this.circleGraphic.endFill();
    this.stage.addChild(this.circleGraphic);
    this.renderer.render(this.stage);
  },
  updating() {
    if (this.CURRENT_INDEX > 0) {
      this.view.classList.add('is--scrolling');
    } else {
      this.view.classList.remove('is--scrolling');
    }
  },
  finalize() {
    if (this.$items.length > 0) {
      const t = this.$items[this.CURRENT_INDEX];
      for (let e = 0; e < this.$items.length; e += 1) {
        const i = this.$items[e];
        if (i.classList.contains('disabled')) {
          i.classList.remove('disabled');
        }
        if (i.classList.contains('enabled')) {
          i.classList.remove('enabled');
        }
        i.style.zIndex = 0;
        if (e !== this.CURRENT_INDEX) {
          i.classList.add('disabled');
        }
      }
      t.classList.add('enabled');
      t.style.zIndex = 1;
    }
  },
};

export const menu = {
  init(route) {
    this.outer = document.querySelector('#page-common-wrapper');
    this.container = document.querySelector('.page-menu-container');
    this.header = document.querySelector('.header-container__main-body');
    this.menuSwitch = this.header.querySelector('.menu-bt');
    this.menuItems = this.container.querySelectorAll('.xxxmenu__item__show');
    this.route = route;

    this.outer.classList.remove('menu-open');
    this.container.classList.remove('open');
    this.header.classList.remove('open');
    this.container.style.display = 'none';
    // this.menuSwitch.classList.remove('open');

    this.IS_CLOSE = true;
    this.ANIMATING = false;

    if (this.container) {
      this.events();
      this.animating();
    }
  },
  events() {
    const that = this;
    this.menuSwitch.addEventListener('click', () => {
      that.toggle();
    });
  },
  toggle() {
    if (!this.ANIMATING) {
      this.IS_CLOSE = this.container.classList.contains('open');
      if (this.IS_CLOSE) {
        this.container.classList.remove('open');
        bindScroll(this.route);
      } else {
        this.container.classList.add('open');
        this.header.classList.add('open');
        this.outer.classList.add('menu-open');
        window.Scrollbar.destroyAll();
      }
      this.animating();
    }
  },
  animating() {
    const that = this;
    that.ANIMATING = true;
    const r = new TimelineLite({
      onComplete() {
        that.ANIMATING = false;
        if (that.IS_CLOSE) {
          that.outer.classList.remove('menu-open');
          that.header.classList.remove('open');
          that.container.style.display = 'none';
        }
      },
    });
    r.kill();
    const items = Array.prototype.slice.call(that.menuItems);
    if (this.IS_CLOSE) {
      // 关闭
      r.add([
        TweenMax.staggerFromTo(items, 0.5, {
          opacity: 1,
          x: 0,
        }, {
          x: -50,
          opacity: 0,
          delay: 0.5,
        }, 0.05),
        TweenLite.to(that.container, 0.05, {
          autoAlpha: 0,
          delay: 0.55,
        }),
      ]);
    } else {
      // 打开
      r.add([
        TweenLite.to(that.container, 0.05, {
          autoAlpha: 1,
          display: 'block',
        }),
        TweenMax.staggerFromTo(items, 0.5, {
          x: -50,
          opacity: 0,
        }, {
          opacity: 1,
          x: 0,
          delay: 0.5,
        }, 0.05),
      ]);
    }
    r.restart();
  },
};

export function clicks() {
  const $creatorItem = $('.creator__item');
  const $creatorD = $('.about__creator-detail');
  const screenWidth = $('body').width();
  const leftOffset = (1765 / 1920) * screenWidth;
  const $close = $('#gbbactbt');
  const $fixText = $creatorItem.parents('.about__section-container').find('.section__fixed-text');
  // const $wrapper = $('#detail__content');
  // let scrollBar = null;
  $creatorItem.on('click', function () {
    const $this = $(this);
    const $img = $this.find('.item__img');
    const $text = $this.find('.item__text');
    const bcr = $img['0'].getBoundingClientRect();
    if (!$creatorD.hasClass('open')) {
      $creatorD.addClass('open');
      // scrollBar = window.Scrollbar.init($wrapper, {});
      window.scrollbar.scrollIntoView($creatorD['0']);
      $this.css({
        zIndex: 3,
      });
      $img.css({
        transform: `translateX(${leftOffset - bcr.x - bcr.width}px)`,
      });
      $text.css({
        display: 'none',
      });
      $fixText.css({
        opacity: 0,
      });

      $this.siblings().css({
        zIndex: 0,
      });

      $this.siblings().find('.item__text').css({
        opacity: 0,
      });
      $this.siblings().find('.item__img').css({
        opacity: 0,
      });
    }
  });
  $close.on('click', () => {
    if ($creatorD.hasClass('open')) {
      $creatorD.removeClass('open');
      $creatorItem.find('.item__img').css({
        transform: 'translateX(0px)',
        opacity: 1,
        // zIndex: 'initial',
      });
      setTimeout(() => {
        $creatorItem.find('.item__text').css({
          opacity: 1,
          display: 'block',
          // zIndex: 'initial',
        });
        $fixText.css({
          opacity: 1,
        });
      }, 300);
    }
  });
}

export const demo2Slides = {
  init() {
    const index = 1;
    this.items = $('.layout__item');

    this.currentItem = this.items.eq(index - 1);
    this.getOtherObjs();

    this.duration = 800 / 1000;
    this.bodyHeight = $(window).height();

    this.isAnimating = false;

    this.timeout = null;

    // 初始化显示
    const r = new TimelineLite({
      onComplete: () => {
        this.isAnimating = false;
        this.currentItem['0'].classList.add('current');
      },
      onStart: () => {
        this.isAnimating = true;
      },
    });
    r.add(TweenMax.fromTo(this.currentItem.find('.left'), this.duration, {
      opacity: 0,
      y: -30,
    }, {
      opacity: 1,
      y: 0,
      ease: Cubic.easeInOut,
    }));
    r.add(TweenMax.fromTo(this.currentItem.find('.right'), this.duration, {
      opacity: 0,
      y: 30,
    }, {
      opacity: 1,
      y: 0,
      ease: Cubic.easeInOut,
    }), 0.3);
    r.pause();
    if (!this.isAnimating) {
      r.play();
    }
  },
  getOtherObjs() {
    const index = this.currentItem.index();
    this.nextItem = this.items.eq(index + 1);
    if (!this.nextItem.length) this.nextItem = this.items.first();
    this.prevItem = this.items.eq(index - 1);
    if (!this.prevItem.length) this.prevItem = this.items.last();
  },
  auotplay() {
    const that = this;
    that.timeout = setTimeout(() => {
      that.next();
    }, 3000);
  },
  prev() {
    const that = this;
    const current = this.prevItem;
    const r = new TimelineLite({
      onComplete: () => {
        that.isAnimating = false;
        that.currentItem['0'].classList.remove('current');
        that.currentItem = current;
        that.currentItem['0'].classList.add('current');
        that.getOtherObjs();
        // that.timeout = setTimeout(() => {
        //   that.next();
        // }, 3000);
      },
      onStart: () => {
        clearTimeout(that.timeout);
        that.isAnimating = true;
      },
    });

    r.add(TweenMax.fromTo(current.find('.left'), that.duration, {
      y: `${that.bodyHeight}px`,
    }, {
      y: 0,
      ease: Cubic.easeInOut,
    }), 0);
    r.add(TweenMax.fromTo(that.currentItem.find('.left'), that.duration, {
      y: 0,
    }, {
      y: `${-that.bodyHeight}px`,
      ease: Cubic.easeInOut,
    }), 0);

    r.add(TweenMax.fromTo(current.find('.right'), that.duration, {
      y: `${-that.bodyHeight}px`,
    }, {
      y: 0,
      ease: Cubic.easeInOut,
    }), 0);
    r.add(TweenMax.fromTo(that.currentItem.find('.right'), that.duration, {
      y: 0,
    }, {
      y: `${that.bodyHeight}px`,
      ease: Cubic.easeInOut,
    }), 0);

    r.pause();
    if (!that.isAnimating) {
      r.play();
    }
  },
  next() {
    const that = this;
    const current = this.nextItem;
    const r = new TimelineLite({
      onComplete: () => {
        that.isAnimating = false;
        that.currentItem['0'].classList.remove('current');
        that.currentItem = current;
        that.currentItem['0'].classList.add('current');
        that.getOtherObjs();
        // that.timeout = setTimeout(() => {
        //   that.next();
        // }, 3000);
      },
      onStart: () => {
        clearTimeout(that.timeout);
        that.isAnimating = true;
      },
    });
    r.add(TweenMax.fromTo(current.find('.left'), that.duration, {
      y: `${-that.bodyHeight}px`,
    }, {
      y: 0,
      ease: Cubic.easeInOut,
    }), 0);
    r.add(TweenMax.fromTo(that.currentItem.find('.left'), that.duration, {
      y: 0,
    }, {
      y: `${that.bodyHeight}px`,
      ease: Cubic.easeInOut,
    }), 0);

    r.add(TweenMax.fromTo(current.find('.right'), that.duration, {
      y: `${that.bodyHeight}px`,
    }, {
      y: 0,
      ease: Cubic.easeInOut,
    }), 0);
    r.add(TweenMax.fromTo(that.currentItem.find('.right'), that.duration, {
      y: 0,
    }, {
      y: `${-that.bodyHeight}px`,
      ease: Cubic.easeInOut,
    }), 0);

    r.pause();
    if (!that.isAnimating) {
      r.play();
    }
  },
};

export function demo2switchMenu() {
  const bt = $('.nav-container');
  if (bt.length > 0) {
    bt.on('click', () => {
      if (bt.hasClass('open')) {
        bt.removeClass('open');
        document.body.classList.remove('menu-open');
        $('.blvd-fixed-menu-container').removeClass('show');
      } else {
        bt.addClass('open');
        $body.removeClass('menu-open');
        document.body.classList.add('menu-open');
        $('.blvd-fixed-menu-container').addClass('show');
      }
    });
  }
}

export function preloadImgs(imglists, delay) {
  const imgs = imglists || [];
  const timeDelay = delay || 100;
  // const size = imgs.length;
  return Promise.all(
    imgs.map((item) => new Promise((res, rej) => {
      const img = new Image();
      img.onload = (e) => {
        // if (size < 10) {

        // } else {
        //   res(e);
        // }
        setTimeout(() => {
          res(e);
        }, 1 * 1000);
      };
      img.onerror = (e) => {
        setTimeout(() => {
          img.src = item;
          rej(e);
        }, timeDelay);
      };
      img.src = item;
    })),
  );
}
