<template>
  <div class="waterfall-wrapper" ref="wrapper" :data-item-width="itemWidth">
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";
const waterfallCoumpute = (() => {
  function calculate(vm, metas) {
    // const temp = metas.filter(item => vm.completedEl.indexOf(item._uid) === -1);
    vm.columnHeight = [];
    metas.forEach(meta => {
      const rect = meta.el;
      let min = 0;
      let minKey = 0;
      let isFirst3 = false;
      if (!vm.$bowserMobile) {
        const rectHeight =
          Math.floor((vm.itemWidth * meta.height) / meta.width) +
          Math.floor(rect.querySelector(".item__text").getBoundingClientRect().height);
        if (vm.columnHeight.length < vm.maxColumn) {
          min = rectHeight;
          minKey = vm.columnHeight.length;
          vm.columnHeight.push(rectHeight);
          isFirst3 = true;
        } else {
          min = Math.min(...vm.columnHeight);
          minKey = Object.keys(vm.columnHeight).find(k => vm.columnHeight[k] === min);
          // eslint-disable-next-line no-param-reassign
          vm.columnHeight[minKey] = vm.columnHeight[minKey] + rectHeight + vm.itemOffset;
        }
        const left =
          Number(minKey) === 0 ? "0px" : `${minKey * vm.itemWidth + minKey * vm.itemOffset}px`;
        const top = isFirst3 ? "0px" : `${vm.columnHeight[minKey] - rectHeight}px`;
        vm.completedEl.push(meta.order);
        rect.style.cssText = `left:${left};top:${top};width:${vm.itemWidth}px;`;
        rect.classList.add("completed");
      }
      rect.querySelector("img").setAttribute("src", meta.src);
      // rect.classList.add("actived");
    });
    // eslint-disable-next-line no-param-reassign
    vm.$el.style.height = vm.columnHeight.length > 0 ? `${Math.max(...vm.columnHeight)}px` : "auto";
  }
  return {
    calculate
  };
})();
export default {
  name: "CWaterfall",
  props: {
    maxColumn: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      wrapperWidth: 0,
      itemOffset: 0,
      itemClass: "waterfall-item",
      tempChildren: [],
      columnHeight: [],
      loading: true,
      completedEl: []
    };
  },
  computed: {
    ...mapState(["$bowserMobile"]),
    itemWidth() {
      return this.wrapperWidth > 0
        ? Math.floor((this.wrapperWidth - (this.maxColumn - 1) * this.itemOffset) / this.maxColumn)
        : 0;
    }
  },
  created() {
    const that = this;
    that.$nextTick(() => {
      that.wrapperWidth = Math.floor(that.$refs.wrapper.getBoundingClientRect().width);
      that.itemOffset = parseInt(
        window.getComputedStyle(that.$refs.wrapper.querySelector(`.${that.itemClass}`), null)
          .marginRight,
        10
      );
    });
    that.$on("reflow", item => {
      that.loading = false;
      that.tempChildren.push(item.meta);
      that.tempChildren.sort((a, b) => a.order - b.order);
      if (that.$children.length === that.tempChildren.length) {
        waterfallCoumpute.calculate(this, that.tempChildren);
        that.$emit("imgLoaded");
      }
    });
  }
};
</script>
