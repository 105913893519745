<template>
  <div class="page-common-wrapper" id="page-common-wrapper">
    <div
      class="project-bg"
      :style="{
        backgroundColor: bg
      }"
      v-if="$route.name !== 'project-detail'"
    ></div>
    <page-header v-if="$route.name !== 'upload-files'"></page-header>
    <router-view />
    <horizontal-page></horizontal-page>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { demo2OnScroll } from '@/assets/utils/common';
import horizontalPage from './common/_horizontal.vue';
import PageHeader from './common/page-header.vue';

export default {
  components: {
    PageHeader,
    horizontalPage,
  },
  computed: {
    ...mapState(['bg', 'types', '$bowserMobile']),
  },
  watch: {
    $route: 'init',
  },
  created() {
    this.getTypes();
  },
  updated() {
    this.$nextTick(() => {
      demo2OnScroll();
    });
  },
  mounted() {
    const that = this;
    that.$nextTick(() => {
      this.initIsMobile();
      if (that.$bowserMobile) {
        // 解决移动端 :active,:hover 无效
        document.body.addEventListener('touchstart', () => {});
      }
      window.addEventListener('resize', that.onResize);
      // document.oncontextmenu = function () {
      //   return false;
      // };
    });
  },
  methods: {
    ...mapActions(['getTypes']),
    ...mapMutations(['setMobile']),
    init() {
      // if (this.$route.meta.defaultBg) {
      //   document.body.style.cssText = `background-color:${this.$route.meta.defaultBg}`;
      // }

      this.$nextTick(() => {
        document.body.classList.remove('nav-open', 'menu-open', 'logo-change');
        window.addEventListener('scroll', demo2OnScroll);
      });
    },
    onResize() {
      this.$screenWidth = window.innerWidth;
      this.initIsMobile();
    },
    initIsMobile() {
      const bowserMobile = Boolean(this.$bowser.mobile);
      const isMobile = window.innerWidth <= 750 || bowserMobile;
      this.setMobile(isMobile);
    },
  },
};
</script>

<style></style>
