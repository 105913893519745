import waterfall from './wrapper.vue';
import waterItem from './item.vue';
import './style.scss';

const install = (Vue) => {
  Vue.component(waterfall.name, waterfall);
  Vue.component(waterItem.name, waterItem);
};

export default install;
