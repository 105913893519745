<template>
  <div
    class="waterfall-item"
    ref="items"
    :style="{
      width: $bowserMobile ? 'ininherit' : `${$parent.itemWidth}px`
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CWaterfallItem",
  props: {
    order: {
      default: 0
    }
  },
  data() {
    return {
      src: this.$attrs["data-src"],
      meta: {}
    };
  },
  computed: {
    ...mapState(["$bowserMobile"])
  },
  created() {},
  mounted() {
    const that = this;
    that.loaded().then(() => {
      //   that.$refs.items.querySelector('img').setAttribute('src', that.src);
      that.$refs.items.classList.add("loaded");
      that.$nextTick(() => {
        this.notify();
      });
    });
  },
  methods: {
    notify() {
      this.$parent.$emit("reflow", this);
    },
    loaded() {
      const that = this;
      return new Promise(resolve => {
        const img = new Image();
        img.onload = () => {
          that.meta = {
            src: that.src,
            width: img.width,
            // height: Math.floor(img.height) + Math.floor(that.$el.querySelector('.item__text').getBoundingClientRect().height),
            height: img.height,
            el: that.$el,
            order: that.order
          };
          resolve();
        };
        img.src = that.src;
      });
    }
  }
};
</script>

<style></style>
