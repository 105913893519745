<template>
  <div class="horizontal_container" v-if="orientation === 90 || orientation === -90">
    <div class="logo">
      <img src="@/assets/images/demo4/logo-demo4.svg" alt="" />
    </div>
    <div class="hint">为了更好的体验，请竖起手机。</div>
  </div>
</template>

<script>
export default {
  name: 'horizontal-page',
  data() {
    return {
      orientation: 0,
    };
  },
  watch: {
    orientation: {
      handler() {
      },
      immediate: true,
    },
  },
  mounted() {
    this.orientation = window.orientation;
    window.addEventListener('orientationchange', this.orientationChange);
  },
  methods: {
    orientationChange() {
      this.orientation = window.orientation;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_mixin";
.horizontal_container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 99999;
  @include flex-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    img {
      width: 60%;
      margin: 0 auto;
    }
  }
  .hint {
    font-size: 22px;
    color: #000;
    margin-top: 45px;
  }
}
</style>
