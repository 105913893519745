import 'babel-polyfill';
import Vue from 'vue';
import $ from 'jquery';
import Scrollbar from 'smooth-scrollbar';
import store from '@/store';
import * as filters from '@/filters';
import fly from '@/api/index';
import { IEVersion } from '@/assets/utils/tools';
import bowser from 'bowser';
import waterfall from '@/plugins/Waterfall';
import App from './App.vue';
import router from './router';
import 'normalize.css';
import '@/assets/scss/common.scss';
import 'nprogress/nprogress.css';
import '@/plugins/elementUI';

Vue.config.productionTip = false;

window.Scrollbar = Scrollbar;
window.$ = $;

Vue.prototype.$fly = fly;
Vue.prototype.$screenHeight = window.innerHeight;
Vue.prototype.$bowser = bowser;

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.use(waterfall);

const ieVersion = IEVersion();

if (ieVersion === -1) {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
} else {
  // ie 不兼容提示
  const hintContainer = document.getElementById('browser-ie-hint-container');
  hintContainer.style.display = 'block';
  hintContainer.querySelector('.version').innerHTML = ieVersion;
}
